<template>
    <van-cell center>
        <template #title>
            <van-image class="cursor-pointer" :src="Env.userPath + user.profile.icon" fit="cover" :radius="10"
                width="4rem" height="4rem" @click="clickIcon()" />
        </template>
        <template #value>
            <div>
                <van-tag class="ml-3 mb-1 cursor-pointer" :color="TagTypes.personal.color" size="medium"
                    v-for="(tag, index) in user.meta.tags.personal" :key="index"
                    @click="removePersonalTag(index, tag)">{{ tag }}</van-tag>
                <van-tag class="ml-3 mb-1 cursor-pointer" :color="TagTypes.system.color" size="medium"
                    v-for="(tag, index) in user.meta.tags.system" :key="index" @click="removeSystemTag(index, tag)">{{
                tag }}</van-tag>
                <br />
                <van-button class="w-7 h-7 mt-2" plain icon="plus" color="#7232dd"
                    @click="showTagInput(user.meta.tags, user.userId)" />
            </div>
        </template>
    </van-cell>
    <van-cell>
        <template #title>
            <div class="flex items-center">
                <div class="flex flex-col">
                    <span>{{ user.profile.firstName + ' ' + user.profile.lastName }}</span>
                    <span class="text-gray-400">{{ user.profile.showName }}</span>
                </div>
                <van-button class="ml-6" v-if="!active && showDelete" type="danger" size="small" icon="delete-o"
                    @click="deleteUser()" />
            </div>
        </template>
        <template #value>
            <div class="flex flex-row-reverse">
                <div v-if="showActive" class="flex flex-col items-center">
                    <span class="text-xs">{{ activeText }}</span>
                    <van-switch v-model="active" size="20px" active-color="#07c160" @change="reportActive()" />
                </div>
            </div>
        </template>
    </van-cell>
    <van-cell v-if="showLog" title="生日" :value="user.profile.dob" />
    <van-cell v-else title="年龄" :value="getAge(user.profile.dob)" />
    <van-cell v-if="showLog" title="上次登录" :value="lastOn" />
</template>

<script>
import {
    ref
} from "vue";
import {
    Tag,
    Cell,
    Switch,
    Button,
    Image as VanImage,
    Dialog
} from "vant";
import Env from "@/logics/Envs.js";
import TagTypes from "@/static_values/StaticTagTypes.js";

export default {
    components: {
        [Tag.name]: Tag,
        [Cell.name]: Cell,
        [Switch.name]: Switch,
        [VanImage.name]: VanImage,
        [Button.name]: Button
    },
    props: {
        user: Object,
        activeText: String,
        showActive: Boolean,
        showDelete: Boolean,
        showLog: {
            type: Boolean,
            default: false
        }
    },
    emits: ["clickIcon", "removePersonalTag", "removeSystemTag", "showTagInput", "reportActive", "deleteUser"],
    setup(props, {
        emit
    }) {
        const active = ref(props.user.active);

        const clickIcon = function () {
            emit("clickIcon");
        };

        const removePersonalTag = async function (tagIndex, tag) {
            try {
                await Dialog.confirm({
                    title: "确认移除标签吗？",
                    message: "将要移除标签[" + tag + "]",
                    confirmButtonColor: "#ee0a24"
                });
                emit("removePersonalTag", tagIndex, props.user.meta.tags.personal, props.user.userId);
            } catch (e) {
                console.log(e);
                return;
            }
        };

        const removeSystemTag = async function (tagIndex, tag) {
            try {
                await Dialog.confirm({
                    title: "确认移除标签吗？",
                    message: "将要移除标签[" + tag + "]",
                    confirmButtonColor: "#ee0a24"
                });
                emit("removeSystemTag", tagIndex, props.user.meta.tags.system, props.user.userId);
            } catch (e) {
                console.log(e);
                return;
            }
        };

        const showTagInput = function (userTags, userId) {
            emit("showTagInput", userTags, userId);
        };

        const reportActive = function () {
            emit("reportActive", props.user, active.value);
        };

        const deleteUser = function () {
            emit("deleteUser", props.user);
        };

        const lastOn = ref("");
        if (props.user.lastOn) {
            lastOn.value = new Date(props.user.lastOn).toLocaleString();
        }

        const nowYear = new Date().getFullYear();
        const getAge = function (dob) {
            const year = parseInt(dob.split("-")[0]);
            return nowYear - year;
        };

        return {
            Env,
            TagTypes,
            active,
            clickIcon,
            removePersonalTag,
            removeSystemTag,
            showTagInput,
            reportActive,
            deleteUser,
            lastOn,
            getAge
        };
    }
}
</script>
